var wipwrp = window.wipwrp || {};

wipwrp.dashboardPopup = (function($,mzr,doc,win) {

    var self = {};
    var body = document.querySelector('body');
    var popup = document.querySelector('.c-home-popup');
    var popupBtn = popup && popup.querySelector('.btn-1')

    self.init = function () {
        _showButton();
        _closeButton();
    }

    function _showButton(){
        if (popup) {
            popup.classList.add('show');
            body.classList.add('popup-open');
            var overlay =  document.createElement('div');
            overlay.classList.add('popup-overlay');
            body.appendChild(overlay);
        }

    }
    function _closeButton(){
        if (popup) {
            popupBtn.addEventListener('click', function() {
                popup.classList.remove('show');
                var overlay = document.querySelector('.popup-overlay');
                body.classList.remove('popup-open');
                setTimeout(() => {
                    overlay.remove();
                }, 600);

            })
        }
    }

    return self;
})(jQuery,Modernizr, document, window);